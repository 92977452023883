// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

